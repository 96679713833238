"use strict"

angular.module("informaApp")
    .service("CookieBannerService", ['$window', function ($window) {
        return {
            initialize: function () {
                $window.cookieconsent.initialise({
                    "palette": {
                        "popup": {
                            "background": "#edeff5",
                            "text": "#838391"
                        },
                        "button": {
                            "background": "#d1497c"
                        }
                    },
                    "theme": "classic",
                    "position": "bottom",
                    "content": {
                        "message": "We use cookies to improve your website experience. To learn about our use of cookies and how you can manage your cookie settings, please see our <a target='_blank' href='/cookie-policy' class='inf-link-pink'>Cookie Policy</a>.  By continuing to use the website, you consent to our use of cookies.",
                        "dismiss": "X",
                        "link": "",
                        "href": null
                    }
                });
            }
        };
    }]);